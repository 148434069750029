var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Row",
    {
      staticClass: "unitReport",
      staticStyle: { height: "100%", padding: "10px" },
    },
    [
      _vm.expand
        ? _c(
            "Col",
            { style: _vm.leftSpan },
            [
              _c(
                "Card",
                {
                  staticStyle: {
                    overflow: "hidden",
                    height: "calc(100vh - 79px)",
                  },
                },
                [
                  _c("Alert", { staticClass: "ivu-alert" }, [
                    _vm._v("单位报告"),
                  ]),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _vm.queryTime
                        ? _c(
                            "RadioGroup",
                            {
                              staticClass: "btngroup",
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                type: "button",
                                size: "small",
                                "button-style": "solid",
                              },
                              on: { "on-change": _vm.pick },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            },
                            [
                              _c("Radio", { attrs: { label: "当日" } }),
                              _c("Radio", { attrs: { label: "当月" } }),
                              _c(
                                "DatePicker",
                                {
                                  attrs: {
                                    open: _vm.datePickerShow,
                                    transfer: "",
                                    type: "daterange",
                                  },
                                  on: { "on-change": _vm.datePickerChange },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      [
                                        _c("Radio", {
                                          attrs: { label: "自定义" },
                                          nativeOn: {
                                            click: function ($event) {
                                              _vm.datePickerShow =
                                                !_vm.datePickerShow
                                            },
                                          },
                                        }),
                                      ],
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _c("Radio", {
                                attrs: { label: "重置" },
                                on: { click: _vm.resetSearchForm },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "Button",
                        {
                          staticStyle: {
                            height: "24px",
                            "margin-left": "6px",
                            "margin-top": "10px",
                          },
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.orderChooseClick },
                        },
                        [_vm._v("合并订单")]
                      ),
                      !_vm.queryTime
                        ? _c(
                            "Button",
                            {
                              staticStyle: {
                                height: "24px",
                                "margin-left": "1px",
                                "margin-top": "10px",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.confirmHistory },
                            },
                            [_vm._v("网报确认")]
                          )
                        : _vm._e(),
                      !_vm.queryTime
                        ? _c(
                            "Button",
                            {
                              staticStyle: {
                                height: "24px",
                                "margin-top": "10px",
                              },
                              attrs: { size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetSearchForm("重置")
                                },
                              },
                            },
                            [_vm._v("重置")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.queryTime
                    ? _c(
                        "div",
                        [
                          _c(
                            "Button",
                            {
                              staticStyle: {
                                height: "24px",
                                "margin-left": "1px",
                                "margin-top": "10px",
                              },
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.confirmHistory },
                            },
                            [_vm._v("网报确认")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.queryTime
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-wrap": "nowrap",
                            "align-items": "center",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c("DatePicker", {
                            attrs: {
                              type: "date",
                              transfer: "",
                              placeholder: "开始时间",
                            },
                            on: { "on-change": _vm.datePickerStartTime },
                            model: {
                              value: _vm.groupUnitForm.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.groupUnitForm, "startDate", $$v)
                              },
                              expression: "groupUnitForm.startDate",
                            },
                          }),
                          _vm._v("  ~  "),
                          _c("DatePicker", {
                            attrs: {
                              type: "date",
                              transfer: "",
                              placeholder: "结束时间",
                            },
                            on: { "on-change": _vm.datePickerEndTime },
                            model: {
                              value: _vm.groupUnitForm.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.groupUnitForm, "endDate", $$v)
                              },
                              expression: "groupUnitForm.endDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("Input", {
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      suffix: "ios-search",
                      placeholder: "请输入单位名称",
                      clearable: "",
                    },
                    on: { "on-change": _vm.search },
                    model: {
                      value: _vm.groupUnitForm.groupUnitName,
                      callback: function ($$v) {
                        _vm.$set(_vm.groupUnitForm, "groupUnitName", $$v)
                      },
                      expression: "groupUnitForm.groupUnitName",
                    },
                  }),
                  _c(
                    "Row",
                    {
                      staticStyle: {
                        "justify-content": "center",
                        height: "calc(100% - 153px)",
                      },
                    },
                    [
                      _vm.groupOrderList.length > 0
                        ? _c(
                            "RadioGroup",
                            {
                              staticClass: "radio-group",
                              model: {
                                value: _vm.radioValue,
                                callback: function ($$v) {
                                  _vm.radioValue = $$v
                                },
                                expression: "radioValue",
                              },
                            },
                            _vm._l(_vm.groupOrderList, function (item, index) {
                              return _c(
                                "Row",
                                {
                                  key: item.id,
                                  staticClass: "row-border",
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.radioChooseClick(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "Col",
                                    {
                                      staticClass: "radio-group-radio",
                                      attrs: { span: 3 },
                                    },
                                    [
                                      _c("Radio", {
                                        staticClass: "label",
                                        attrs: { label: item.id },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "Col",
                                    {
                                      staticClass: "radio-group-content",
                                      attrs: { span: 21 },
                                    },
                                    [
                                      _c(
                                        "Row",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "6px",
                                          },
                                        },
                                        [
                                          _c(
                                            "Col",
                                            {
                                              staticClass: "date",
                                              attrs: { span: 11 },
                                            },
                                            [_vm._v(_vm._s(item.orderCode))]
                                          ),
                                          item.deleteId && item.deleteId == "1"
                                            ? _c(
                                                "Col",
                                                {
                                                  staticClass: "date",
                                                  staticStyle: {
                                                    border:
                                                      "1px solid rgb(66, 106, 179)",
                                                    "border-radius": "8px",
                                                    "text-align": "center",
                                                    color: "rgb(66, 106, 179)",
                                                  },
                                                  attrs: { span: 5 },
                                                },
                                                [_vm._v("已报")]
                                              )
                                            : _c(
                                                "Col",
                                                {
                                                  staticClass: "date",
                                                  staticStyle: {
                                                    border: "1px solid #ff9900",
                                                    "border-radius": "8px",
                                                    "text-align": "center",
                                                    color: "#ff9900",
                                                  },
                                                  attrs: { span: 5 },
                                                },
                                                [_vm._v("未报")]
                                              ),
                                          _c(
                                            "Col",
                                            {
                                              staticClass: "date",
                                              staticStyle: {
                                                "text-align": "right",
                                              },
                                              attrs: { span: 8 },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.formatTime(
                                                    item.signingTime
                                                  )
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "Row",
                                        [
                                          _c(
                                            "Col",
                                            {
                                              staticClass: "company",
                                              attrs: {
                                                span: 20,
                                                title: item.groupUnitName,
                                              },
                                            },
                                            [_vm._v(_vm._s(item.groupUnitName))]
                                          ),
                                          _c("Col", { attrs: { span: 4 } }, [
                                            item.sporadicPhysical &&
                                            item.sporadicPhysical == 1
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "border-purple",
                                                  },
                                                  [_vm._v(" 零 ")]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "border-purple",
                                                  },
                                                  [_vm._v(" 团 ")]
                                                ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c("no-data"),
                      _vm.checkPersonLoading
                        ? _c(
                            "Spin",
                            { attrs: { fix: "" } },
                            [
                              _c("Icon", {
                                staticClass: "demo-spin-icon-load",
                                attrs: { type: "ios-loading", size: "18" },
                              }),
                              _c("div", [_vm._v("加载中...")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.groupOrderList.length > 0
                        ? _c("Page", {
                            attrs: {
                              current: _vm.groupUnitForm.pageNumber,
                              total: _vm.groupDataTotal,
                              simple: "",
                            },
                            on: { "on-change": _vm.personChangePageNum },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "expand" },
        [
          _c("Icon", {
            staticClass: "icon",
            attrs: { type: _vm.expandIcon, size: "16" },
            on: { click: _vm.changeExpand },
          }),
        ],
        1
      ),
      _c(
        "Col",
        { style: _vm.span },
        [
          _vm.groupOrderList.length > 0
            ? _c(
                "Card",
                { staticStyle: { height: "calc(100vh - 80px)" } },
                [
                  _c("Alert", { staticClass: "ivu-alert-left" }, [
                    _vm._v(" 报告信息 "),
                  ]),
                  _c(
                    "Form",
                    {
                      ref: "unitReportForm",
                      staticStyle: { "margin-top": "16px" },
                      attrs: {
                        model: _vm.unitReportForm,
                        rules: _vm.unitReportFormRule,
                        "label-width": 90,
                      },
                    },
                    [
                      _c(
                        "Row",
                        {
                          staticStyle: {
                            "margin-bottom": "15px",
                            "margin-top": "20px",
                          },
                        },
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "报告编号" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      type: "text",
                                      placeholder: "提交后系统自动生成",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.unitReportForm.code,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.unitReportForm,
                                          "code",
                                          $$v
                                        )
                                      },
                                      expression: "unitReportForm.code",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "体检类型" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.unitReportForm.physicalType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.unitReportForm,
                                            "physicalType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "unitReportForm.physicalType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.inspectTypePriority,
                                      function (item, i) {
                                        return _c(
                                          "Option",
                                          {
                                            key: item.id,
                                            attrs: { value: item.value },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        { staticStyle: { "margin-bottom": "15px" } },
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "体检单位" } },
                                [
                                  _c(
                                    "Select",
                                    {
                                      attrs: {
                                        disabled: _vm.disabled,
                                        placeholder: "请选择",
                                        clearable: "",
                                      },
                                      model: {
                                        value: _vm.unitReportForm.physicalUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.unitReportForm,
                                            "physicalUnit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "unitReportForm.physicalUnit",
                                      },
                                    },
                                    _vm._l(
                                      _vm.unitDataArry,
                                      function (item, i) {
                                        return _c(
                                          "Option",
                                          {
                                            key: item.id,
                                            attrs: { value: item.name },
                                          },
                                          [_vm._v(_vm._s(item.name) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "委托单位" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.unitReportForm.entrustUnit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.unitReportForm,
                                          "entrustUnit",
                                          $$v
                                        )
                                      },
                                      expression: "unitReportForm.entrustUnit",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Row",
                        { staticStyle: { "margin-bottom": "15px" } },
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "报告日期",
                                    prop: "physicalDate",
                                  },
                                },
                                [
                                  _c("DatePicker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      disabled: _vm.disabled,
                                      placeholder: "请选择报告日期",
                                    },
                                    model: {
                                      value: _vm.unitReportForm.physicalDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.unitReportForm,
                                          "physicalDate",
                                          $$v
                                        )
                                      },
                                      expression: "unitReportForm.physicalDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                { attrs: { label: "体检人数" } },
                                [
                                  _c("Input", {
                                    attrs: {
                                      type: "text",
                                      disabled: _vm.disabled,
                                    },
                                    model: {
                                      value: _vm.unitReportForm.physicalNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.unitReportForm,
                                          "physicalNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "unitReportForm.physicalNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      this.physicalType == "健康体检"
                        ? _c(
                            "Row",
                            { staticStyle: { "margin-bottom": "15px" } },
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "体检项目" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          rows: 3,
                                          type: "textarea",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.unitReportForm.physicalProject,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.unitReportForm,
                                              "physicalProject",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "unitReportForm.physicalProject",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "评论依据",
                                        prop: "evaluationBasis",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          rows: 3,
                                          type: "textarea",
                                          placeholder: "请输入评论依据",
                                          clearable: "",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.unitReportForm.evaluationBasis,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.unitReportForm,
                                              "evaluationBasis",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "unitReportForm.evaluationBasis",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      this.physicalType != "健康体检"
                        ? _c(
                            "Row",
                            { staticStyle: { "margin-bottom": "15px" } },
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "危害因素" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          rows: 3,
                                          type: "textarea",
                                          placeholder: "暂无",
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          "on-focus": _vm.handleHarmSelect,
                                        },
                                        model: {
                                          value:
                                            _vm.unitReportForm.hazardFactors,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.unitReportForm,
                                              "hazardFactors",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "unitReportForm.hazardFactors",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    { attrs: { label: "体检项目" } },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          rows: 3,
                                          type: "textarea",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.unitReportForm.physicalProject,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.unitReportForm,
                                              "physicalProject",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "unitReportForm.physicalProject",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      this.physicalType == "健康体检"
                        ? _c(
                            "Row",
                            { staticStyle: { "margin-bottom": "15px" } },
                            [
                              _c(
                                "Col",
                                { attrs: { span: "24" } },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass: "choice",
                                      attrs: {
                                        type: "primary",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordChoose("zjclyj")
                                        },
                                      },
                                    },
                                    [_vm._v("选 ")]
                                  ),
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "体检结论与处理意见",
                                        prop: "concludingObservations",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          rows: 4,
                                          type: "textarea",
                                          placeholder:
                                            "请输入体检结论与处理意见",
                                          clearable: "",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.unitReportForm
                                              .concludingObservations,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.unitReportForm,
                                              "concludingObservations",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "unitReportForm.concludingObservations",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      this.physicalType != "健康体检"
                        ? _c(
                            "Row",
                            { staticStyle: { "margin-bottom": "15px" } },
                            [
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "评论依据",
                                        prop: "evaluationBasis",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          rows: 4,
                                          type: "textarea",
                                          placeholder: "请输入评论依据",
                                          clearable: "",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.unitReportForm.evaluationBasis,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.unitReportForm,
                                              "evaluationBasis",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "unitReportForm.evaluationBasis",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "Button",
                                    {
                                      staticClass: "choice",
                                      attrs: {
                                        type: "primary",
                                        disabled: _vm.disabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleWordChoose("zjclyj")
                                        },
                                      },
                                    },
                                    [_vm._v("选 ")]
                                  ),
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "体检结论与处理意见",
                                        prop: "concludingObservations",
                                      },
                                    },
                                    [
                                      _c("Input", {
                                        attrs: {
                                          rows: 4,
                                          type: "textarea",
                                          placeholder:
                                            "请输入体检结论与处理意见",
                                          clearable: "",
                                          disabled: _vm.disabled,
                                        },
                                        model: {
                                          value:
                                            _vm.unitReportForm
                                              .concludingObservations,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.unitReportForm,
                                              "concludingObservations",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "unitReportForm.concludingObservations",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.isUpdateUnitPage
                    ? _c(
                        "el-tabs",
                        {
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: { type: "border-card" },
                          on: { "tab-click": _vm.tabsClick },
                          model: {
                            value: _vm.tabsValue,
                            callback: function ($$v) {
                              _vm.tabsValue = $$v
                            },
                            expression: "tabsValue",
                          },
                        },
                        [
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "检查人数统计表",
                                    name: "1",
                                  },
                                },
                                [
                                  _vm.tabsValue == "1"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue1",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                            "show-footer": "",
                                            "footer-method": _vm.footerMethod,
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "职业危害因素",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "80",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "allNum",
                                              title: "参检人数（个）",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "noAbnormalNum",
                                              title: "未见异常人数（个）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "otherAbnormalNum",
                                              title: "其他异常人数（个）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "recheckNum",
                                              title: "复查人数（个）",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "tabooNum",
                                              title: "职业禁忌证人数（个）",
                                              align: "center",
                                              minWidth: "160",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "diseaseNum",
                                              title: "疑似职业病人数（个）",
                                              align: "center",
                                              minWidth: "160",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "检查结果分析表",
                                    name: "2",
                                  },
                                },
                                [
                                  _vm.tabsValue == "2"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue2",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                            "show-footer": "",
                                            "footer-method": _vm.footerMethod,
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "职业危害因素",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "80",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "recheckNums",
                                              title:
                                                "与接触职业病危害因素相关的异常指标人次或复查（人次）",
                                              align: "center",
                                              width: "400",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "otherAbnormalNum",
                                              title: "其他疾病或异常（人次）",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "noAbnormalNum",
                                              title: "目前未见异常（人数）",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-tab-pane",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                lazy: true,
                                label: "体检人员汇总表",
                                name: "7",
                              },
                            },
                            [
                              _vm.tabsValue == "7"
                                ? _c(
                                    "vxe-table",
                                    {
                                      ref: "tabsValue7",
                                      attrs: {
                                        border: "",
                                        resizable: "",
                                        data: _vm.tableData,
                                        "max-height": _vm.tableMaxHeight,
                                        "show-overflow": "",
                                      },
                                    },
                                    [
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "testNum",
                                          title: "编号",
                                          align: "center",
                                          minWidth: "130",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "personName",
                                          title: "姓名",
                                          align: "center",
                                          minWidth: "70",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "mobile",
                                          title: "手机号",
                                          align: "center",
                                          minWidth: "110",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "sex",
                                          title: "性别",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "age",
                                          title: "年龄",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "idCard",
                                          title: "身份证号码",
                                          align: "center",
                                          minWidth: "180",
                                        },
                                      }),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "工种",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接触有害工龄（年）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "department",
                                          title: "所属部门",
                                          align: "center",
                                          minWidth: "90",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "registDate",
                                          title: "体检时间",
                                          align: "center",
                                          minWidth: "100",
                                        },
                                      }),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType == "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "physicalType",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "isRecheck",
                                          title: "是否正常",
                                          align: "center",
                                          minWidth: "90",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "conclusion",
                                          title: "体检结果",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "handleOpinion",
                                          title: "处理意见",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                lazy: true,
                                label: "复查人员汇总表",
                                name: "3",
                              },
                            },
                            [
                              _vm.tabsValue == "3"
                                ? _c(
                                    "vxe-table",
                                    {
                                      ref: "tabsValue3",
                                      attrs: {
                                        border: "",
                                        resizable: "",
                                        data: _vm.tableData,
                                        "max-height": _vm.tableMaxHeight,
                                      },
                                    },
                                    [
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "testNum",
                                          title: "编号",
                                          align: "center",
                                          minWidth: "130",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "personName",
                                          title: "姓名",
                                          align: "center",
                                          minWidth: "70",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "mobile",
                                          title: "手机号",
                                          align: "center",
                                          minWidth: "110",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "sex",
                                          title: "性别",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "age",
                                          title: "年龄",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "idCard",
                                          title: "身份证号码",
                                          align: "center",
                                          minWidth: "180",
                                        },
                                      }),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "工种",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接触有害工龄（年）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "registDate",
                                          title: "体检时间",
                                          align: "center",
                                          minWidth: "100",
                                        },
                                      }),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType == "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "physicalType",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "reason",
                                          title: "复查原因",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "portfolioProjectName",
                                          title: "复查项目",
                                          align: "center",
                                          minWidth: "220",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "职业禁忌证汇总表",
                                    name: "4",
                                  },
                                },
                                [
                                  _vm.tabsValue == "4"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue4",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "testNum",
                                              title: "编号",
                                              align: "center",
                                              minWidth: "130",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "personName",
                                              title: "姓名",
                                              align: "center",
                                              minWidth: "70",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "mobile",
                                              title: "手机号",
                                              align: "center",
                                              minWidth: "110",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "sex",
                                              title: "性别",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "age",
                                              title: "年龄",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "idCard",
                                              title: "身份证号码",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "工种",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接触有害工龄（年）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "registDate",
                                              title: "体检时间",
                                              align: "center",
                                              minWidth: "100",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "conclusion",
                                              title: "体检结果",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "handleOpinion",
                                              title: "处理意见",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "疑似职业病汇总表",
                                    name: "5",
                                  },
                                },
                                [
                                  _vm.tabsValue == "5"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue5",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "testNum",
                                              title: "编号",
                                              align: "center",
                                              minWidth: "130",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "personName",
                                              title: "姓名",
                                              align: "center",
                                              minWidth: "70",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "mobile",
                                              title: "手机号",
                                              align: "center",
                                              minWidth: "110",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "sex",
                                              title: "性别",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "age",
                                              title: "年龄",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "idCard",
                                              title: "身份证号码",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "工种",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接触有害工龄（年）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "registDate",
                                              title: "体检时间",
                                              align: "center",
                                              minWidth: "100",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "conclusion",
                                              title: "体检结果",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "handleOpinion",
                                              title: "处理意见",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "其他异常结果汇总表",
                                    name: "6",
                                  },
                                },
                                [
                                  _vm.tabsValue == "6"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue6",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "testNum",
                                              title: "编号",
                                              align: "center",
                                              minWidth: "130",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "personName",
                                              title: "姓名",
                                              align: "center",
                                              minWidth: "70",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "mobile",
                                              title: "手机号",
                                              align: "center",
                                              minWidth: "110",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "sex",
                                              title: "性别",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "age",
                                              title: "年龄",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "idCard",
                                              title: "身份证号码",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "工种",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接触有害工龄（年）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "registDate",
                                              title: "体检时间",
                                              align: "center",
                                              minWidth: "100",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "conclusion",
                                              title: "体检结果",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "handleOpinion",
                                              title: "处理意见",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-tab-pane",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                lazy: true,
                                label: "复查结果汇总表",
                                name: "8",
                              },
                            },
                            [
                              _vm.tabsValue == "8"
                                ? _c(
                                    "vxe-table",
                                    {
                                      ref: "tabsValue8",
                                      attrs: {
                                        border: "",
                                        resizable: "",
                                        data: _vm.tableData,
                                        "max-height": _vm.tableMaxHeight,
                                        "show-overflow": "",
                                      },
                                    },
                                    [
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "testNum",
                                          title: "编号",
                                          align: "center",
                                          minWidth: "130",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "personName",
                                          title: "姓名",
                                          align: "center",
                                          minWidth: "70",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "mobile",
                                          title: "手机号",
                                          align: "center",
                                          minWidth: "110",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "sex",
                                          title: "性别",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "age",
                                          title: "年龄",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "idCard",
                                          title: "身份证号码",
                                          align: "center",
                                          minWidth: "180",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "hazardFactorsText",
                                          title: "危害因素",
                                          align: "center",
                                          minWidth: "150",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "workTypeText",
                                          title: "工种",
                                          align: "center",
                                          minWidth: "120",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "exposureWorkYear",
                                          title: "接触有害工龄（年）",
                                          align: "center",
                                          minWidth: "150",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "registDate",
                                          title: "体检时间",
                                          align: "center",
                                          minWidth: "100",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "workStateText",
                                          title: "体检类型",
                                          align: "center",
                                          minWidth: "90",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "conclusion",
                                          title: "复查体检结果",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "handleOpinion",
                                          title: "复查处理意见",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isUpdateUnitPage
                    ? _c(
                        "el-tabs",
                        {
                          staticStyle: { width: "100%", "margin-top": "10px" },
                          attrs: { type: "border-card" },
                          on: { "tab-click": _vm.tabsClick },
                          model: {
                            value: _vm.tabsValue,
                            callback: function ($$v) {
                              _vm.tabsValue = $$v
                            },
                            expression: "tabsValue",
                          },
                        },
                        [
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "检查人数统计表",
                                    name: "1",
                                  },
                                },
                                [
                                  _vm.tabsValue == "1"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue1",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                            "show-footer": "",
                                            "footer-method": _vm.footerMethod,
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "职业危害因素",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "80",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "allNum",
                                              title: "参检人数（个）",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "noAbnormalNum",
                                              title: "未见异常人数（个）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "otherAbnormalNum",
                                              title: "其他异常人数（个）",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "recheckNum",
                                              title: "复查人数（个）",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "tabooNum",
                                              title: "职业禁忌证人数（个）",
                                              align: "center",
                                              minWidth: "160",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "diseaseNum",
                                              title: "疑似职业病人数（个）",
                                              align: "center",
                                              minWidth: "160",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "检查结果分析表",
                                    name: "2",
                                  },
                                },
                                [
                                  _vm.tabsValue == "2"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue2",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                            "show-footer": "",
                                            "footer-method": _vm.footerMethod,
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "职业危害因素",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类型",
                                              align: "center",
                                              minWidth: "80",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "recheckNums",
                                              title:
                                                "与接触职业病危害因素相关的异常指标人次或复查（人次）",
                                              align: "center",
                                              width: "400",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "otherAbnormalNum",
                                              title: "其他疾病或异常（人次）",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "noAbnormalNum",
                                              title: "目前未见异常（人数）",
                                              align: "center",
                                              minWidth: "180",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-tab-pane",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                lazy: true,
                                label: "体检人员汇总表",
                                name: "7",
                              },
                            },
                            [
                              _vm.tabsValue == "7"
                                ? _c(
                                    "vxe-table",
                                    {
                                      ref: "tabsValue7",
                                      attrs: {
                                        border: "",
                                        resizable: "",
                                        data: _vm.tableData,
                                        "max-height": _vm.tableMaxHeight,
                                        "show-overflow": "",
                                      },
                                    },
                                    [
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "testNum",
                                          title: "编号",
                                          align: "center",
                                          minWidth: "130",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "personName",
                                          title: "姓名",
                                          align: "center",
                                          minWidth: "70",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "sex",
                                          title: "性别",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "age",
                                          title: "年龄",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "接触危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "岗位",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接害工龄",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "department",
                                          title: "所属部门",
                                          align: "center",
                                          minWidth: "90",
                                        },
                                      }),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType == "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "physicalType",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "conclusion",
                                          title: "体检结果",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "handleOpinion",
                                          title: "处理意见",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                lazy: true,
                                label: "复查人员汇总表",
                                name: "3",
                              },
                            },
                            [
                              _vm.tabsValue == "3"
                                ? _c(
                                    "vxe-table",
                                    {
                                      ref: "tabsValue3",
                                      attrs: {
                                        border: "",
                                        resizable: "",
                                        data: _vm.tableData,
                                        "max-height": _vm.tableMaxHeight,
                                      },
                                    },
                                    [
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "testNum",
                                          title: "编号",
                                          align: "center",
                                          minWidth: "130",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "personName",
                                          title: "姓名",
                                          align: "center",
                                          minWidth: "70",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "sex",
                                          title: "性别",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "age",
                                          title: "年龄",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "接触危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "岗位",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接害工龄",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType != "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          })
                                        : _vm._e(),
                                      this.physicalType == "健康体检"
                                        ? _c("vxe-column", {
                                            attrs: {
                                              field: "physicalType",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "reason",
                                          title: "复查原因",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "portfolioProjectName",
                                          title: "复查项目",
                                          align: "center",
                                          minWidth: "220",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "职业禁忌证汇总表",
                                    name: "4",
                                  },
                                },
                                [
                                  _vm.tabsValue == "4"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue4",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "testNum",
                                              title: "编号",
                                              align: "center",
                                              minWidth: "130",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "personName",
                                              title: "姓名",
                                              align: "center",
                                              minWidth: "70",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "sex",
                                              title: "性别",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "age",
                                              title: "年龄",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "接触危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "岗位",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接害工龄",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "conclusion",
                                              title: "职业性结果",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "handleOpinion",
                                              title: "处理意见",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "疑似职业病汇总表",
                                    name: "5",
                                  },
                                },
                                [
                                  _vm.tabsValue == "5"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue5",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "testNum",
                                              title: "编号",
                                              align: "center",
                                              minWidth: "130",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "personName",
                                              title: "姓名",
                                              align: "center",
                                              minWidth: "70",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "sex",
                                              title: "性别",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "age",
                                              title: "年龄",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "接触危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "岗位",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接害工龄",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "conclusion",
                                              title: "职业性结果",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "handleOpinion",
                                              title: "处理意见",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          this.physicalType != "健康体检"
                            ? _c(
                                "el-tab-pane",
                                {
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    lazy: true,
                                    label: "其他异常结果汇总表",
                                    name: "6",
                                  },
                                },
                                [
                                  _vm.tabsValue == "6"
                                    ? _c(
                                        "vxe-table",
                                        {
                                          ref: "tabsValue6",
                                          attrs: {
                                            border: "",
                                            resizable: "",
                                            data: _vm.tableData,
                                            "max-height": _vm.tableMaxHeight,
                                            "show-overflow": "",
                                          },
                                        },
                                        [
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "testNum",
                                              title: "编号",
                                              align: "center",
                                              minWidth: "130",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "personName",
                                              title: "姓名",
                                              align: "center",
                                              minWidth: "70",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "sex",
                                              title: "性别",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "age",
                                              title: "年龄",
                                              align: "center",
                                              minWidth: "60",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "hazardFactorsText",
                                              title: "接触危害因素",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workTypeText",
                                              title: "岗位",
                                              align: "center",
                                              minWidth: "120",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "exposureWorkYear",
                                              title: "接害工龄",
                                              align: "center",
                                              minWidth: "150",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "workStateText",
                                              title: "体检类别",
                                              align: "center",
                                              minWidth: "90",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "conclusion",
                                              title: "非职业性结果",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                          _c("vxe-column", {
                                            attrs: {
                                              field: "handleOpinion",
                                              title: "处理意见",
                                              align: "center",
                                              minWidth: "200",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-tab-pane",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: {
                                lazy: true,
                                label: "复查结果汇总表",
                                name: "8",
                              },
                            },
                            [
                              _vm.tabsValue == "8"
                                ? _c(
                                    "vxe-table",
                                    {
                                      ref: "tabsValue8",
                                      attrs: {
                                        border: "",
                                        resizable: "",
                                        data: _vm.tableData,
                                        "max-height": _vm.tableMaxHeight,
                                        "show-overflow": "",
                                      },
                                    },
                                    [
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "testNum",
                                          title: "编号",
                                          align: "center",
                                          minWidth: "130",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "personName",
                                          title: "姓名",
                                          align: "center",
                                          minWidth: "70",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "mobile",
                                          title: "手机号",
                                          align: "center",
                                          minWidth: "110",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "sex",
                                          title: "性别",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "age",
                                          title: "年龄",
                                          align: "center",
                                          minWidth: "60",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "idCard",
                                          title: "身份证号码",
                                          align: "center",
                                          minWidth: "180",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "hazardFactorsText",
                                          title: "危害因素",
                                          align: "center",
                                          minWidth: "150",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "workTypeText",
                                          title: "工种",
                                          align: "center",
                                          minWidth: "120",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "exposureWorkYear",
                                          title: "接触有害工龄（年）",
                                          align: "center",
                                          minWidth: "150",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "registDate",
                                          title: "体检时间",
                                          align: "center",
                                          minWidth: "100",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "workStateText",
                                          title: "体检类型",
                                          align: "center",
                                          minWidth: "90",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "conclusion",
                                          title: "体检结果",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "handleOpinion",
                                          title: "处理意见",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                      _c("vxe-column", {
                                        attrs: {
                                          field: "reviewResult",
                                          title: "复查结果",
                                          align: "center",
                                          minWidth: "200",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        bottom: "-15px",
                        position: "relative",
                        width: "100%",
                        "background-color": "#fff",
                        "text-align": "center",
                      },
                    },
                    [
                      _vm.updateShow
                        ? _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-create-outline",
                              },
                              on: { click: _vm.updateData },
                            },
                            [_vm._v("修改")]
                          )
                        : _vm._e(),
                      _vm.saveShow
                        ? _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-download-outline",
                                loading: _vm.saveLoading,
                              },
                              on: { click: _vm.saveData },
                            },
                            [_vm._v("保存 ")]
                          )
                        : _vm._e(),
                      _c(
                        "Button",
                        {
                          attrs: {
                            type: "primary",
                            icon: "ios-cloud-download-outline",
                            loading: _vm.saveLoading,
                          },
                          on: { click: _vm.exportData },
                        },
                        [_vm._v(" 导出 ")]
                      ),
                      this.physicalType != "健康体检" &&
                      _vm.unitReportForm.physicalDate &&
                      _vm.updateShow
                        ? _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-cloud-upload",
                              },
                              on: { click: _vm.previewData },
                            },
                            [_vm._v("预览")]
                          )
                        : _vm._e(),
                      this.physicalType == "健康体检" &&
                      _vm.unitReportForm.physicalDate &&
                      _vm.updateShow
                        ? _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-cloud-upload",
                              },
                              on: { click: _vm.previewTemplateJKTJ },
                            },
                            [_vm._v("预览")]
                          )
                        : _vm._e(),
                      _vm.recheck &&
                      this.physicalType != "健康体检" &&
                      _vm.unitReportForm.physicalDate &&
                      _vm.updateShow
                        ? _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-cloud-upload",
                              },
                              on: { click: _vm.previewReviewData },
                            },
                            [_vm._v("复查报告 ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "Card",
                [
                  _c("no-data", {
                    staticStyle: {
                      height: "calc(100vh - 128px)",
                      overflow: "hidden",
                      display: "flex",
                      "flex-direction": "row",
                      "justify-content": "center",
                    },
                    attrs: { width: "150" },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c("preview-template", {
        ref: "unitReportPdf",
        attrs: { previewName: _vm.previewName, TTemplateId: _vm.TTemplateId },
        model: {
          value: _vm.previewShow,
          callback: function ($$v) {
            _vm.previewShow = $$v
          },
          expression: "previewShow",
        },
      }),
      _c("drawer", {
        attrs: {
          width: "400",
          modalTitle: _vm.title,
          type: _vm.type,
          inspectType: _vm.unitReportForm.physicalType,
          hazardFactorsText: _vm.unitReportForm.hazardFactors,
          isMultiple: _vm.isMultiple,
        },
        on: { getSelectedWords: _vm.getSelectedWords },
        model: {
          value: _vm.wordChooseShow,
          callback: function ($$v) {
            _vm.wordChooseShow = $$v
          },
          expression: "wordChooseShow",
        },
      }),
      _c("order-choose", {
        model: {
          value: _vm.orderChooseShow,
          callback: function ($$v) {
            _vm.orderChooseShow = $$v
          },
          expression: "orderChooseShow",
        },
      }),
      _c("harmDrawer", {
        attrs: {
          modalTitle: _vm.title,
          selectedHarmList: _vm.selectedHarmList,
          selectType: _vm.seltType,
        },
        on: { harmReback: _vm.harmReback },
        model: {
          value: _vm.harmShow,
          callback: function ($$v) {
            _vm.harmShow = $$v
          },
          expression: "harmShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }